<template functional>
  <!--FOR PRICE LIST PROPOSAL-->
  <span
    v-if="
      props.publicationState &&
        props.publicationState.publicationStateId !== null &&
        props.publicationState.publicationStateId !== undefined
    "
    :class="
      `price-list-status-${props.publicationState.publicationStateId} price-list-status-text`
    "
  >
    {{
      parent.$t(
        `priceList.publicationStateDecode.${props.publicationState.publicationStateId}`
      )
    }}
  </span>
  <!--FOR PRICE LIST -->
  <span
    v-else
    :class="`price-list-status-${props.status} price-list-status-text`"
  >
    {{ parent.$t(`priceList.statusDecode.${props.status}`) }}
  </span>
</template>
<style lang="scss">
.price-list-status-text {
  font-weight: bold;
  &.price-list-status--1,
  &.price-list-status-3 {
    color: #ffb800;
  }
  &.price-list-status-4,
  &.price-list-status-0 {
    color: #ff0000;
  }
  &.price-list-status-5,
  &.price-list-status-2,
  &.price-list-status-1 {
    color: #5bbe0d !important;
  }
}
</style>
<script>
export default {
  name: "PriceListProposalStatus",
  props: {
    publicationState: { type: Object, required: false },
    status: { type: Number, required: false }
  }
};
</script>
